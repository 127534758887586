import { useDispatch } from "react-redux";
import { logout } from "../actions/login.action";
import { clearMobiles } from "../actions/mobiles.action";
import { clearReportsList } from "../actions/customReports.action";
import { useNavigate } from "react-router";
import { useEffect } from "react";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];
const AppLogout = (props) => {
  const navigate = useNavigate();
  const clearFields = () => {
    dispatch(clearMobiles());
    dispatch(clearReportsList());
  };
  const dispatch = useDispatch();
  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1800000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    dispatch(logout());
    clearFields();
    localStorage.clear();
    navigate("/");
  };

  return props.children;
};

export default AppLogout;

import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { getColorList } from "../../actions/lists.action";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getBranchesList } from "../../actions/lists.action";
import { getNetworkList } from "../../actions/lists.action";
import { getInternalMemoryList } from "../../actions/lists.action";
import { getGradeList } from "../../actions/lists.action";
import { getItemModelList } from "../../actions/itemModel.action";
import { getItemMakeList } from "../../actions/itemMake.action";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { format } from "date-fns";
import { filterBy } from "@progress/kendo-data-query";
import { getMobiles } from "../../actions/mobiles.action";

export const FilterMobile = () => {
  const gradeListApi = useSelector((state) => state.allLists.gradeList);
  const itemmakeListApi = useSelector((state) => state.itemMake.itemMakeList);
  const branchesListApi = useSelector((state) => state.allLists.branchesList);
  const networkListApi = useSelector((state) => state.allLists.networkList);
  const colorListApi = useSelector((state) => state.allLists.colorList);
  const internalMemoryListApi = useSelector(
    (state) => state.allLists.internalMemoryList
  );
  const itemModelListApi = useSelector(
    (state) => state.itemModel.itemModelList
  );
  const dispatch = useDispatch();
  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    dispatch(getBranchesList(localStorage.getItem("username")));
    dispatch(getNetworkList());
    dispatch(getColorList());
    dispatch(getInternalMemoryList());
    dispatch(getGradeList());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setitemMakeList(itemmakeListApi);
    }, 400);
  }, [itemmakeListApi]);
  const [netwrokData, setNetwrokData] = React.useState([]);
  const [itemsOnly, setItemsOnly] = useState(null);
  const [itemMake, setItemMake] = useState(null);
  const [itemMakeName, setItemMakeName] = useState(null);
  const [itemMakeList, setitemMakeList] = useState([]);
  const [itemModelList, setItemModelList] = useState(itemModelListApi);
  const [branchesList, setbranchesList] = useState(branchesListApi);
  const [networkList, setnetworkList] = useState(networkListApi);
  const [gradeList, setgradeList] = useState(gradeListApi);
  const [internalMemoryList, setinternalMemoryList] = useState(
    internalMemoryListApi
  );
  const [colorList, setcolorList] = useState(colorListApi);
  const [itemModel, setItemModel] = useState(null);
  const [itemModelName, setItemModelName] = useState(null);
  var open;
  if (window.innerWidth <= 768) {
    open = false;
  } else {
    open = true;
  }
  const [isFilterOpen, setIsFilterOpen] = useState(open);

  const formRef = useRef();
  const IMEISerialNoRef = useRef();
  const shopsComboRef = useRef();
  const networkComboRef = useRef();
  const colorComboRef = useRef();
  const internalMemoryComboRef = useRef();
  const itemConditionComboRef = useRef();
  const customerIdRef = useRef();
  const supplierIdRef = useRef();
  const batchNumberRef = useRef();
  const supplierInvPORef = useRef();
  const VATOnPurchaseIDRef = useRef();

  const filterData = (filter) => {
    let query = ``;
    if (itemMakeName !== null) {
      query = `?ItemMakeName=${itemMakeName}`;
    }
    if (itemModelName !== null) {
      query += `&ItemModelID=${itemModel}&ModelName=${itemModelName}`;
    }
    if (shopsComboRef.current?.value) {
      query += `&ShopID=${shopsComboRef.current?.value.id}`;
    }
    if (saleDateFrom && saledatefromcheck) {
      query += `&SaleDateFrom=${saleDateFrom}`;
    }
    if (saleDateTo && saledatetocheck) {
      query += `&SaleDateTo=${saleDateTo}`;
    }
    if (purchaseDateFrom && purchasedatefromcheck) {
      query += `&PurchaseDateFrom=${purchaseDateFrom}`;
    }
    if (purchaseDateTo && purchasedatetocheck) {
      query += `&PurchaseDateTo=${purchaseDateTo}`;
    }
    if (itemReceivedDateFrom && itemRecieveddatefromcheck) {
      query += `&ItemReceivedDateFrom=${itemReceivedDateFrom}`;
    }
    if (itemReceivedDateTo && itemRecieveddatetocheck) {
      query += `&ItemReceivedDateTo=${itemReceivedDateTo}`;
    }
    if (returnDateFrom && itemReturndatefromcheck) {
      query += `&ReturnDateFrom=${returnDateFrom}`;
    }
    if (returnDateTo && itemReturndatetocheck) {
      query += `&ReturnDateTo=${returnDateTo}`;
    }
    if (RMADateFrom && itemRMAdateFromcheck) {
      query += `&RMADateFrom=${RMADateFrom}`;
    }
    if (RMADateTo && itemRMAdatetocheck) {
      query += `&RMADateTo=${RMADateTo}`;
    }
    if (networkComboRef.current?.value) {
      query += `&Network=${networkComboRef.current?.value.description}`;
    }
    if (colorComboRef.current?.value) {
      query += `&Color=${colorComboRef.current?.value.description}`;
    }
    if (internalMemoryComboRef.current?.value) {
      query += `&InternalMemory=${internalMemoryComboRef.current?.value.description}`;
    }
    if (itemConditionComboRef.current?.value) {
      query += `&ItemCondition=${itemConditionComboRef.current?.value.description}`;
    }
    if (batchNumberRef.current?.value) {
      query += `&BatchNumber=${batchNumberRef.current?.value}`;
    }
    if (supplierInvPORef.current?.value) {
      query += `&SupplierInvPONum=${supplierInvPORef.current?.value}`;
    }
    if (VATOnPurchaseIDRef.current?.value) {
      query += `&VATOnPurchaseID=${VATOnPurchaseIDRef.current?.value}`;
    }
    dispatch(getMobiles(query));
    console.log(query);
    // const data = allData.slice();
    // return filterBy(data, filter);
  };
  // console.log(networkLists)
  const filterMakeName = (filter) => {
    const data = itemmakeListApi.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setitemMakeList(filterMakeName(event.filter));
  };
  const filterModelName = (filter) => {
    const data = itemModelListApi.slice();
    return filterBy(data, filter);
  };
  const filterChangeModelName = (event) => {
    setItemModelList(filterModelName(event.filter));
  };
  const filterBranchesData = (filter) => {
    const data = branchesListApi.slice();
    return filterBy(data, filter);
  };
  const shopsFilterChange = (event) => {
    setbranchesList(filterBranchesData(event.filter));
  };
  const filternetworkData = (filter) => {
    const data = networkListApi.slice();
    return filterBy(data, filter);
  };
  const networkFilterChange = (event) => {
    setnetworkList(filternetworkData(event.filter));
  };
  const filtercolorData = (filter) => {
    const data = colorListApi.slice();
    return filterBy(data, filter);
  };
  const colorFilterChange = (event) => {
    setcolorList(filtercolorData(event.filter));
  };
  const filterinternalData = (filter) => {
    const data = internalMemoryListApi.slice();
    return filterBy(data, filter);
  };
  const internalFilterChange = (event) => {
    setinternalMemoryList(filterinternalData(event.filter));
  };
  const filtergradeData = (filter) => {
    const data = gradeListApi.slice();
    return filterBy(data, filter);
  };
  const gradeFilterChange = (event) => {
    setgradeList(filtergradeData(event.filter));
  };

  const date = new Date();
  const [saleDateFrom, setSaleDateFrom] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [saleDateFromDateSelector, setSaleDateFromDateSelector] =
    React.useState(date);
  const onChangeSaleDateFrom = (event) => {
    console.log(event.value);

    setSaleDateFromDateSelector(event.value);
    let saleDateFrom = null;
    if (event.value) {
      saleDateFrom = format(new Date(event.value), "yyyy/MM/dd");
    }
    console.log(saleDateFrom);

    setSaleDateFrom(saleDateFrom);
  };

  const [saleDateTo, setSaleDateTo] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [saleDateToDateSelector, setSaleDateToDateSelector] =
    React.useState(date);
  const onChangeSaleDateTo = (event) => {
    setSaleDateToDateSelector(event.value);
    let saleDateTo = null;
    if (event.value) {
      saleDateTo = format(new Date(event.value), "yyyy/MM/dd");
    }
    console.log(saleDateTo);

    setSaleDateTo(saleDateTo);
  };

  const [purchaseDateFrom, setPurchaseDateFrom] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [purchaseDateFromDateSelector, setPurchaseDateFromDateSelector] =
    React.useState(date);
  const onChangePurchaseDateFrom = (event) => {
    setPurchaseDateFromDateSelector(event.value);
    let purchaseDateFrom = null;
    if (event.value) {
      purchaseDateFrom = format(new Date(event.value), "yyyy/MM/dd");
    }
    console.log(purchaseDateFrom);
    setPurchaseDateFrom(purchaseDateFrom);
  };

  const [purchaseDateTo, setPurchaseDateTo] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [purchaseDateToDateSelector, setPurchaseDateToDateSelector] =
    React.useState(date);
  const onChangePurchaseDateTo = (event) => {
    setPurchaseDateToDateSelector(event.value);
    let purchaseDateTo = null;
    if (event.value) {
      purchaseDateTo = format(new Date(event.value), "yyyy/MM/dd");
    }
    setPurchaseDateTo(purchaseDateTo);
  };

  const [itemReceivedDateFrom, setItemReceivedDateFrom] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [
    itemReceivedDateFromDateSelector,
    setItemReceivedDateFromDateSelector,
  ] = React.useState(date);
  const onChangeItemReceivedDateFrom = (event) => {
    setItemReceivedDateFromDateSelector(event.value);
    let itemReceivedDateFrom = null;
    if (event.value) {
      itemReceivedDateFrom = format(new Date(event.value), "yyyy/MM/dd");
    }
    setItemReceivedDateFrom(itemReceivedDateFrom);
  };

  const [itemReceivedDateTo, setItemReceivedDateTo] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [itemReceivedDateToDateSelector, setItemReceivedDateToDateSelector] =
    React.useState(date);
  const onChangeItemReceivedDateTo = (event) => {
    setItemReceivedDateToDateSelector(event.value);
    let itemReceivedDateTo = null;
    if (event.value) {
      itemReceivedDateTo = format(new Date(event.value), "yyyy/MM/dd");
    }
    setItemReceivedDateTo(itemReceivedDateTo);
  };

  const [returnDateFrom, setReturnDateFrom] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [returnDateFromDateSelector, setReturnDateFromDateSelector] =
    React.useState(date);
  const onChangeReturnDateFrom = (event) => {
    setReturnDateFromDateSelector(event.value);
    let returnDateFrom = null;
    if (event.value) {
      returnDateFrom = format(new Date(event.value), "yyyy/MM/dd");
    }
    setReturnDateFrom(returnDateFrom);
  };

  const [returnDateTo, setReturnDateTo] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [returnDateToDateSelector, setReturnDateToDateSelector] =
    React.useState(date);
  const onChangeReturnDateTo = (event) => {
    setReturnDateToDateSelector(event.value);
    let returnDateTo = null;
    if (event.value) {
      returnDateTo = format(new Date(event.value), "yyyy/MM/dd");
    }
    setReturnDateTo(returnDateTo);
  };

  const [RMADateFrom, setRMADateFrom] = React.useState(
    format(date, "yyyy/MM/dd")
  );
  const [RMADateFromDateSelector, setRMADateFromDateSelector] =
    React.useState(date);
  const onChangeRMADateFrom = (event) => {
    setRMADateFromDateSelector(event.value);
    let RMADateFrom = null;
    if (event.value) {
      RMADateFrom = format(new Date(event.value), "yyyy/MM/dd");
    }
    setRMADateFrom(RMADateFrom);
  };

  const [RMADateTo, setRMADateTo] = React.useState(format(date, "yyyy/MM/dd"));
  const [RMADateToDateSelector, setRMADateToDateSelector] =
    React.useState(date);
  const onChangeRMADateTo = (event) => {
    setRMADateToDateSelector(event.value);
    let RMADateTo = null;
    if (event.value) {
      RMADateTo = format(new Date(event.value), "yyyy/MM/dd");
    }
    setRMADateTo(RMADateTo);
  };

  const ItemsOnly = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Stock",
      value: "Stock",
    },
    {
      label: "Sold",
      value: "Sold",
    },
    {
      label: "RMA",
      value: "RMA",
    },
    {
      label: "Reserved",
      value: "Reserved",
    },
  ];

  const changeItemsOnly = (e) => {
    setItemsOnly(e.value);
  };
  const changeItemMake = (e) => {
    if (e.value) {
      setItemMake(e.value.id);
      setItemMakeName(e.value.itemMakeName);

      dispatch(getItemModelList(e.value.id));
    } else {
      setItemModel(null);
      setItemMake(null);
      setItemMakeName(null);
    }
    // setItemMake(e.value.id);
    // dispatch(getItemModelList(e.value.id));
  };
  const changeItemModel = (e) => {
    if (e.value) {
      setItemModel(e.value.id);
      setItemModelName(e.value.modelName);
    } else {
      setItemModel(null);
      setItemModelName(null);
    }
  };
  const enableFilter = (e) => {
    setIsFilterOpen(!isFilterOpen);
  };
  const clearSearch = (e) => {
    console.log(formRef);
    // formRef.getKendoForm()
    // setItemMake="";
    // setItemModel= "";
    // itemConditionComboRef = "";

    // setItemMake(null)
    // batchNumberRef.current.value= ""
  };
  // const networkFilterChange = (e) => {
  //     setNetwrokData(e.value);
  // }
  const [saledatefromcheck, setsaledatefromscheck] = useState(false);
  const [saledatetocheck, setsaledatetoscheck] = useState(false);
  const [purchasedatefromcheck, setpurchasedatefromscheck] = useState(false);
  const [purchasedatetocheck, setpurchasedatetoscheck] = useState(false);
  const [itemRecieveddatefromcheck, setitemRecievedatefromscheck] =
    useState(false);
  const [itemRecieveddatetocheck, setitemRecievedatetoscheck] = useState(false);
  const [itemReturndatefromcheck, setitemReturndatefromscheck] =
    useState(false);
  const [itemReturndatetocheck, setitemReturndatetoscheck] = useState(false);
  const [itemRMAdateFromcheck, setitemRMAdateFromscheck] = useState(false);
  const [itemRMAdatetocheck, setitemRMAdatetoscheck] = useState(false);

  const defaultShopItem = {
    id: 0,
    description: "[ALL SHOPS]",
  };

  return (
    <div className="card-container">
      <React.Fragment>
        <div
          className="d-flex justify-space-between"
          onClick={enableFilter}
          style={{ cursor: "pointer" }}
        >
          <div className="heading">Search</div>
          <div>
            <span className="k-icon k-i-filter mt-5"></span>
          </div>
        </div>
        {isFilterOpen && (
          <form ref={formRef}>
            <div>
              <div className="d-flex justify-space-between mblFlexDirectionColumn">
                <div className="viewMobilesField">
                  <Label>Make Name&nbsp;</Label>
                  {/* <Input /> */}
                  <ComboBox
                    className="fieldWidth"
                    data={itemMakeList}
                    textField="itemMakeName"
                    filterable={true}
                    onFilterChange={filterChange}
                    onChange={changeItemMake}
                  />
                </div>
                <div className="viewMobilesField">
                  <Label>Model Name&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={itemMake ? itemModelList : []}
                    textField="modelName"
                    filterable={true}
                    onFilterChange={filterChangeModelName}
                    onChange={changeItemModel}
                  />
                </div>
                <div className="viewMobilesField">
                  <Label>IMEI Serial No List&nbsp;</Label>
                  <Input className="fieldWidth" ref={IMEISerialNoRef} />
                </div>
                <div className="viewMobilesField">
                  <Label>Shops&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={branchesList}
                    textField="description"
                    filterable={true}
                    onFilterChange={shopsFilterChange}
                    ref={shopsComboRef}
                    defaultValue={defaultShopItem}
                  />
                </div>
              </div>
              <div className="d-flex justify-space-between mblFlexDirectionColumn">
                <div className="viewMobilesField">
                  <Label>Sale Date&nbsp;</Label>
                  <div className="d-flex inputGroup mblFlexDirectionColumn">
                    <Checkbox
                      onChange={(e) => {
                        setsaledatefromscheck(e.value);
                      }}
                      value={saledatefromcheck}
                    />
                    <DatePicker
                      className="datepicker mr-6"
                      format={"yyyy/MM/dd"}
                      value={saleDateFromDateSelector}
                      onChange={onChangeSaleDateFrom}
                      defaultValue={saleDateFromDateSelector}
                      placeholder="Select Sale Date From"
                    />
                    <Checkbox
                      onChange={(e) => {
                        setsaledatetoscheck(e.value);
                      }}
                      value={saledatetocheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={saleDateToDateSelector}
                      onChange={onChangeSaleDateTo}
                      defaultValue={saleDateToDateSelector}
                      placeholder="Select Sale Date From"
                    />
                  </div>
                </div>

                <div className="viewMobilesField">
                  <Label>Purchase Date&nbsp;</Label>
                  <div className="d-flex inputGroup mblFlexDirectionColumn">
                    <Checkbox
                      onChange={(e) => {
                        setpurchasedatefromscheck(e.value);
                      }}
                      value={purchasedatefromcheck}
                    />
                    <DatePicker
                      className="datepicker mr-6"
                      format={"yyyy/MM/dd"}
                      value={purchaseDateFromDateSelector}
                      onChange={onChangePurchaseDateFrom}
                      defaultValue={purchaseDateFromDateSelector}
                      placeholder="Select Purchase Date From"
                    />
                    <Checkbox
                      onChange={(e) => {
                        setpurchasedatetoscheck(e.value);
                      }}
                      value={purchasedatetocheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={purchaseDateToDateSelector}
                      onChange={onChangePurchaseDateTo}
                      defaultValue={purchaseDateToDateSelector}
                      placeholder="Select Purchase Date To"
                    />
                  </div>
                </div>

                <div className="viewMobilesField">
                  <Label>Item Received Date&nbsp;</Label>
                  <div className="d-flex inputGroup mblFlexDirectionColumn">
                    <Checkbox
                      onChange={(e) => {
                        setitemRecievedatefromscheck(e.value);
                      }}
                      value={itemRecieveddatefromcheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={itemReceivedDateFromDateSelector}
                      onChange={onChangeItemReceivedDateFrom}
                      defaultValue={itemReceivedDateFromDateSelector}
                      placeholder="Select Item Received Date From"
                    />
                    <Checkbox
                      onChange={(e) => {
                        setitemRecievedatetoscheck(e.value);
                      }}
                      value={itemRecieveddatetocheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={itemReceivedDateToDateSelector}
                      onChange={onChangeItemReceivedDateTo}
                      defaultValue={itemReceivedDateToDateSelector}
                      placeholder="Select Item Received Date To"
                    />
                  </div>
                </div>

                <div className="viewMobilesField">
                  <Label>Return Date&nbsp;</Label>
                  <div className="d-flex inputGroup mblFlexDirectionColumn">
                    <Checkbox
                      onChange={(e) => {
                        setitemReturndatefromscheck(e.value);
                      }}
                      value={itemReturndatefromcheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={returnDateFromDateSelector}
                      onChange={onChangeReturnDateFrom}
                      defaultValue={returnDateFromDateSelector}
                      placeholder="Select Return Date From"
                    />
                    <Checkbox
                      onChange={(e) => {
                        setitemReturndatetoscheck(e.value);
                      }}
                      value={itemReturndatetocheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={returnDateToDateSelector}
                      onChange={onChangeReturnDateTo}
                      defaultValue={returnDateToDateSelector}
                      placeholder="Select Return Date To"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-space-between mblFlexDirectionColumn">
                <div className="viewMobilesField">
                  <Label>RMA Date&nbsp;</Label>
                  <div className="d-flex inputGroup mblFlexDirectionColumn">
                    <Checkbox
                      onChange={(e) => {
                        setitemRMAdateFromscheck(e.value);
                      }}
                      value={itemRMAdateFromcheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={RMADateFromDateSelector}
                      onChange={onChangeRMADateFrom}
                      defaultValue={RMADateFromDateSelector}
                      placeholder="Select RMA Date From"
                    />
                    <Checkbox
                      onChange={(e) => {
                        setitemRMAdatetoscheck(e.value);
                      }}
                      value={itemRMAdatetocheck}
                    />
                    <DatePicker
                      className="datepicker"
                      format={"yyyy/MM/dd"}
                      value={RMADateToDateSelector}
                      onChange={onChangeRMADateTo}
                      defaultValue={RMADateToDateSelector}
                      placeholder="Select RMA Date To"
                    />
                  </div>
                </div>
                <div className="viewMobilesField d-none">
                  <Label>Customer Id&nbsp;</Label>
                  <Input className="fieldWidth" ref={customerIdRef} />
                </div>
                <div className="viewMobilesField d-none">
                  <Label>Supplier Id&nbsp;</Label>
                  <Input className="fieldWidth" ref={supplierIdRef} />
                </div>
                <div className="viewMobilesField">
                  <Label>Network&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={networkList}
                    textField="description"
                    filterable={true}
                    ref={networkComboRef}
                    onFilterChange={networkFilterChange}
                  />
                </div>
              </div>
              <div className="d-flex justify-space-between mblFlexDirectionColumn">
                <div className="viewMobilesField">
                  <Label>Color&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={colorList}
                    textField="description"
                    filterable={true}
                    ref={colorComboRef}
                    onFilterChange={colorFilterChange}
                  />
                </div>
                <div className="viewMobilesField">
                  <Label>Internal Memory&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={internalMemoryList}
                    textField="description"
                    filterable={true}
                    ref={internalMemoryComboRef}
                    onFilterChange={internalFilterChange}
                  />
                </div>
                <div className="viewMobilesField">
                  <Label>Item Condition&nbsp;</Label>
                  <ComboBox
                    className="fieldWidth"
                    data={gradeList}
                    textField="description"
                    filterable={true}
                    ref={itemConditionComboRef}
                    onFilterChange={gradeFilterChange}
                  />
                </div>
                <div className="viewMobilesField">
                  <Label>Batch Number&nbsp;</Label>
                  <Input className="fieldWidth" ref={batchNumberRef} />
                </div>
              </div>

              <div className="d-flex justify-space-between mblFlexDirectionColumn">
                <div className="viewMobilesField">
                  <Label>Supplier Inv PO Num&nbsp;</Label>
                  <Input className="fieldWidth" ref={supplierInvPORef} />
                </div>
                <div className="viewMobilesField">
                  <Label>VAT On Purchase ID&nbsp;</Label>
                  <Input className="fieldWidth" ref={VATOnPurchaseIDRef} />
                </div>
                <div className="viewMobilesField d-flex">
                  <Label>____Items only:&nbsp;</Label>
                  <RadioGroup
                    data={ItemsOnly}
                    onChange={changeItemsOnly}
                    className="flex-direction-row k-list-horizontal inputGroup radio-pd"
                  />
                </div>
              </div>

              <div className="d-flex">
                <button type="button" className="Btn mt-5" onClick={filterData}>
                  Search
                </button>
                <button
                  type="button"
                  className="Btn mt-5 ml-5"
                  onClick={clearSearch}
                >
                  Clear Search
                </button>
              </div>
            </div>
          </form>
        )}
      </React.Fragment>
    </div>
  );
};

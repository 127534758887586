import {
  GET_BRANCHES,
  GET_NETWORKS,
  GET_COLORS,
  GET_INTERNAL_MEMORY,
  GET_GRADES,
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  ERROR,
  GET_REPORTS_LIST
} from "../types/index";

import * as api from "../api/index.js";

export const getBranchesList = (username) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getBranchesList(username);
    dispatch({ type: GET_BRANCHES, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getNetworkList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getNetworkList();
    dispatch({ type: GET_NETWORKS, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getColorList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getColorList();
    dispatch({ type: GET_COLORS, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getReportsList= () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getReportsList();
    dispatch({ type: GET_REPORTS_LIST, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getInternalMemoryList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getInternalMemoryList();
    dispatch({ type: GET_INTERNAL_MEMORY, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getGradeList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getGradeList();
    dispatch({ type: GET_GRADES, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

import {
  LOGGED_IN,
  LOGGED_OUT,
  LOGGED_IN_AGAIN,
  LOGGING_IN_ERROR,
  START_LOADING,
  CLEAR_ERROR,
  STOP_LOADING,
  SUCCESS,
  ERROR,
} from "../types/index";

import * as api from "../api/index.js";

export const loggedIn = (payload) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.loginUser(payload);
    dispatch({ type: LOGGED_IN, payload: data.data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: LOGGING_IN_ERROR });
    dispatch({ type: ERROR, payload: error });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: LOGGED_OUT });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearError = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ReloggedIn = (payload) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: LOGGED_IN_AGAIN });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: LOGGING_IN_ERROR });
    dispatch({ type: ERROR, payload: error });
  }
};

import React from "react";
import { ViewMobiles } from "../viewMobiles/viewMobiles";
import { CustomReports } from "../customReports/customReports";
import SideDrawer from "../../components/sideBarHamburger";


const MainPage = () => {
  const [currentOption, setCurrentOption] = React.useState(0);
  const setoption = (num) => {
    setCurrentOption(num);
  };
  return (
    <React.Fragment>
      <SideDrawer setoption={setoption}/>
      <div className="pageShow">
        {currentOption === 0 ? <h1 style={{marginLeft:"20px"}}>Dashboard</h1> : ""}
        {currentOption === 1 ? <ViewMobiles /> : ""}
        {currentOption === 2 ? <CustomReports /> : ""}
      </div>
    </React.Fragment>
  );
};

export default MainPage;

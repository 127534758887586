import {
  CLEAR_ERROR,
  LOGGED_IN,
  LOGGED_IN_AGAIN,
  LOGGED_OUT,
  LOGGING_IN_ERROR,
  START_LOADING,
  STOP_LOADING,
} from "../types/index.js";

const LoginReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case LOGGED_IN:
      return {
        ...state,
        isSuccess: true,
        loggedInUser: action.payload,
      };
    case LOGGED_OUT:
      return {
        ...state,
        isSuccess: false,
      };
    case LOGGING_IN_ERROR:
      return {
        ...state,
        isLoginError: true,
      };
    case LOGGED_IN_AGAIN:
      return {
        ...state,
        isSuccess:true,
        loggedInUser: {
          username:"abc"
        }
      }
    case CLEAR_ERROR:
      return {
        ...state,
        isLoginError: false,
      };
    default:
      return state;
  }
};
export default LoginReducer;

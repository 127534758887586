import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import { Login } from "./pages/Login/login.jsx";
import { ProSidebarProvider } from "react-pro-sidebar";
import MainPage from "./pages/mainPage/mainPage.jsx";
import AppLogout from "./components/appLogOut.jsx";
const App = () => {
  const item = localStorage.getItem("_t");
  return (
    <ProSidebarProvider>
      <BrowserRouter>
        <AppLogout>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            {
              <Route
                exact
                path="/app"
                element={item === null ? <Login /> : <MainPage />}
              />
            }
            <Route
              exact
              path="/viewmobiles"
              element={item === null ? <Login /> : <MainPage />}
            />
            <Route
              exact
              path="/customreports"
              element={item === null ? <Login /> : <MainPage />}
            />
          </Routes>
        </AppLogout>
      </BrowserRouter>
    </ProSidebarProvider>
  );
};

export default App;

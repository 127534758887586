import {
  GET_ITEM_MODEL,
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  ERROR,
} from "../types/index";

import * as api from "../api/index.js";

export const getItemModelList = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getItemModelList(id);
    dispatch({ type: GET_ITEM_MODEL, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

import {
    GET_REPORTS,
    START_LOADING,
    STOP_LOADING,
    CLEAR_REPORTS,
    SUCCESS,
    ERROR,
    LOAD_REPORT
  } from "../types/index";
  
  import * as api from "../api/index.js";
  
  export const getReportsList = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getReportsList();
      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };
  export const clearReportsList = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      dispatch({ type: CLEAR_REPORTS});
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS});
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };
  export const loadReport = (reportId, shopId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.loadReport(reportId, shopId);
      dispatch({ type: LOAD_REPORT, payload: data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };
  
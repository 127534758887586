import {
  LOAD_REPORT,
  GET_REPORTS,
  START_LOADING,
  STOP_LOADING,
  CLEAR_REPORTS,
} from "../types/index.js";

const GetReportsReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_REPORTS:
      return {
        ...state,
        reportsList: action.payload,
        isSuccess: true,
      };
    case LOAD_REPORT:
      return {
        ...state,
        loadReportData: action.payload,
        isSuccess: true,
      };
    case CLEAR_REPORTS:
      return {
        ...state,
        reportsList: [],
        loadReportData: [],
        isSuccess: true,
      };

    default:
      return state;
  }
};
export default GetReportsReducer;

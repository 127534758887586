import axios from "axios";
export const API = axios.create({
  baseURL: "https://misonlineapi.azurewebsites.net",
});
export const TOKEN = ``;
export const loginUser = (payload) =>
  API.post(`/Authentication`, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-mis-authentication": "MISLogin",
    },
  });

API.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("_t")}`;
  return req;
});
export const getMobiles = (query) => API.get(`/Mobiles${query}`);
export const getBranchesList = (username) => API.get(`/Lists/Branches?username=${username}`);
export const getNetworkList = () => API.get(`/Lists/Networks`);
export const getColorList = () => API.get(`/Lists/Colors`);
export const getInternalMemoryList = () => API.get(`/Lists/InternalMemory`);
export const getGradeList = () => API.get(`/Lists/Grades`);
export const getItemMakeList = () => API.get(`/ItemMake`);
export const getItemModelList = (id) => API.get(`/ItemModel?makeID=${id}`);
export const getReportsList = (id) => API.get(`/CustomReports`);
export const loadReport = (reportId, shopId) => API.get(`/CustomReports/ReportData?reportid=${reportId}&shopid=${shopId}`);


import {
  GET_MOBILES,
  START_LOADING,
  STOP_LOADING,
  CLEAR_MOBILES,
  SUCCESS,
  ERROR,
} from "../types/index";

import * as api from "../api/index.js";

export const getMobiles = (query) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getMobiles(query);
    dispatch({ type: GET_MOBILES, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const clearMobiles = (query) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: CLEAR_MOBILES });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

import { GET_ITEM_MODEL, START_LOADING, STOP_LOADING } from "../types/index.js";

const GetItemModelReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_ITEM_MODEL:
      return {
        ...state,
        itemModelList: action.payload,
        isSuccess: true,
      };
    default:
      return state;
  }
};
export default GetItemModelReducer;

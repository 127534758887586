 import { format } from "date-fns";
 

 export const DateFormatCell = (props) => {

   let dateStyle = "dd/MM/yyyy HH:mm";
   const dateField = props.dataItem[props.field];

   if (dateField) {
     return <td>{format(new Date(dateField), dateStyle)} </td>;
   } else {
     return <td></td>;
   }
 };

 export const DateFormat = (dateField, dateStyle) => {
   if (dateField) {
     return format(new Date(dateField), dateStyle);
   } else {
     return "";
   }
 };


 export const AmountFormatCell = (props) => {
  const amount = props.dataItem[props.field];
  return <td style={{ textAlign: "right" }}> {AmountFormat(amount)} </td>;
};

export const AmountFormat = (amount) => {
  if (amount !== null && !isNaN(amount)) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "";
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { getMobiles } from "../../actions/mobiles.action";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FilterMobile } from "./filterMobiles";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {  DateFormatCell, AmountFormatCell } from "../../components/common/formatter";

export const ViewMobiles = () => {
  const { isSuccess, mobilesList } = useSelector((state) => state.mobilesList);
  const dispatch = useDispatch();
  const [list, setMobilesList] = useState([]);
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  let gridPDFExport;
  const exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };
  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  // useEffect(() => {
  //   dispatch(getMobiles());
  // }, []);
  useEffect(() => {
    if (isSuccess) {
      setMobilesList(mobilesList);
    }
  }, [isSuccess, mobilesList]);

  let pagerSettings = {
    pageSizes: true,
  };
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const grid = (
    <Grid
      data={process(list, {
        skip: dataState.skip,
        take: dataState.take,
      })}
      {...dataState}
      onDataStateChange={dataStateChange}
      pageable={state.pageable}
      pageSize={state.pageSize}
    >
      <GridColumn field="branchName" title="Shop" width="100px" />
      <GridColumn field="itemMakeName" title="Make Name" width="100px" />
      <GridColumn field="itemModelName" title="Model Name" width="110px" />
      <GridColumn field="itemIMEISerialNo" title="IMEI 1" width="100px" />
      <GridColumn field="itemIMEISerialNo2" title="IMEI 2" width="100px" />
      <GridColumn field="supplierName" title="Supplier Name" width="120px" />
      <GridColumn field="purchasePrice" title="Purchase Price" width="120px" cell={AmountFormatCell} />
      <GridColumn field="retailPrice" title="Retail Price" width="100px" cell={AmountFormatCell}/>
      <GridColumn field="minimumAskPrice" title="Min Ask Price" width="110px" cell={AmountFormatCell}/>
      <GridColumn field="purchaseDate" title="Purchase Date" width="120px" cell={DateFormatCell}/>
      <GridColumn field="saleDate" title="Sale Date" width="100px" cell={DateFormatCell}/>
      <GridColumn
        field="internalComments"
        title="Internal Comments"
        width="150px"
      />
      <GridColumn field="itemColor" title="Item Color" width="100px" />
      <GridColumn
        field="internalStorage"
        title="Internal Storage"
        width="130px"
      />
      <GridColumn field="itemCondition" title="Item Condition" width="120px" />
      <GridColumn field="markedAsRMA" title="Marked As RMA" width="130px" />
      <GridColumn
        field="vatOnPurchaseAmount"
        title="Vat On Purchase Amount"
        width="50px"
      />
      <GridColumn field="isReserved" title="Is Reserved" width="100px" />
      <GridColumn
        field="batteryPercent"
        title="Battery Percent"
        width="120px"
      />
      <GridColumn
        field="countryOfOrigin"
        title="Country Of Origin"
        width="150px"
      />
    </Grid>
  );

  return (
    <div>
      <FilterMobile />
      <div className="buttons">
        <GridToolbar>
          <button title="Export PDF" className="Btn" onClick={exportPDF}>
            Export PDF
          </button>
          <button title="Export Excel" className="Btn" onClick={excelExport}>
            Export to Excel
          </button>
        </GridToolbar>
        <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
          {grid}
        </GridPDFExport>
      </div>

      <div className="card-container">
        <React.Fragment>
          <ExcelExport data={list} ref={_export}>
            {" "}
            {grid}
          </ExcelExport>
        </React.Fragment>
      </div>

      <br/>
      <br/>
      <br/>
    </div>
  );
};

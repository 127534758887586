import {
  GET_BRANCHES,
  GET_NETWORKS,
  GET_COLORS,
  GET_INTERNAL_MEMORY,
  GET_GRADES,
  START_LOADING,
  STOP_LOADING,
  GET_REPORTS_LIST
} from "../types/index.js";

const GetListsReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_BRANCHES:
      return {
        ...state,
        branchesList: action.payload,
        isSuccess: true,
      };
    case GET_NETWORKS:
      return {
        ...state,
        networkList: action.payload,
        isSuccess: true,
      };
    case GET_COLORS:
      return {
        ...state,
        colorList: action.payload,
        isSuccess: true,
      };
    case GET_INTERNAL_MEMORY:
      return {
        ...state,
        internalMemoryList: action.payload,
        isSuccess: true,
      };
    case GET_GRADES:
      return {
        ...state,
        gradeList: action.payload,
        isSuccess: true,
      };
    case  GET_REPORTS_LIST:
      return {
        ...state,
        reportsList: action.payload,
        isSuccess: true,
      };
    default:
      return state;
  }
};
export default GetListsReducer;

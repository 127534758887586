import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { getBranchesList } from "../../actions/lists.action";
import { getReportsList } from "../../actions/lists.action";
import { loadReport } from "../../actions/customReports.action";
import {
  DataResult,
  process,
  State,
  filterBy,
} from "@progress/kendo-data-query";

export const CustomReports = () => {
  const reportsListApi = useSelector((state) => state.allLists.reportsList);
  const branchesListApi = useSelector((state) => state.allLists.branchesList);
  const { loadReportData } = useSelector((state) => state.loadReportReducer);
  const [columnsList, setColumnsList] = useState([]);
  const shopsComboRef = useRef();
  const reportsComboRef = useRef();
  const gridRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      setreportsList(reportsListApi);
    }, 400);
  }, [reportsListApi]);

  const dispatch = useDispatch();
  const [list, setLists] = React.useState([]);
  useEffect(() => {
    if (list[0] !== null && list[0] !== undefined) {
      setColumnsList(Object.keys(list[0]));
    }
  }, [list]);
  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
  });
  const [branchesList, setbranchesList] = useState(branchesListApi);
  const [reportsList, setreportsList] = useState(reportsListApi);

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const filterBranchesData = (filter) => {
    const data = branchesListApi.slice();
    return filterBy(data, filter);
  };
  const shopsFilterChange = (event) => {
    setbranchesList(filterBranchesData(event.filter));
  };
  const filterReportsData = (filter) => {
    const data = reportsListApi.slice();
    return filterBy(data, filter);
  };
  const reportsFilterChange = (event) => {
    setreportsList(filterReportsData(event.filter));
  };
  const [state, setState] = React.useState(createState(0, 10));
  const [dataResult, setDataResult] = React.useState();
  const dataStateChange = (e) => {
    setDataResult(process(list, e.dataState));
    setDataState(e.dataState);
  };
  useEffect(() => {
    dispatch(getBranchesList(localStorage.getItem("username")));
  }, []);
  useEffect(() => {
    if (loadReportData) {
      setLists(loadReportData);
    }
  }, [loadReportData]);

  const loadReportDataEvent = (e) => {
    const reportId = reportsComboRef.current?.value
      ? reportsComboRef.current?.value.id
      : null;
    const shopId = shopsComboRef.current?.value
      ? shopsComboRef.current?.value.id
      : null;
    setDataState({
      take: dataState.take,
      skip: 0,
    });
    dispatch(loadReport(reportId, shopId));
  };
  const grid = list && (
    <Grid
      data={process(list, {
        skip: dataState.skip,
        take: dataState.take,
      })}
      onDataStateChange={dataStateChange}
      {...dataState}
      pageable={state.pageable}
      pageSize={state.pageSize}
    >
      {/* { columnsList.map((item,i) =>{return <GridColumn field={item} title={item} key={i} />})} */}
      {
        <GridColumn
          field="username_tx"
          title="Username"
          width={!columnsList.includes("username_tx") ? 0 : ""}
        />
      }
      {
        <GridColumn
          field="ItemModelName"
          title="Model Name"
          width={!columnsList.includes("ItemModelName") ? 0 : ""}
        />
      }
      {
        <GridColumn
          field="ItemMakeName"
          title="Maker Name"
          width={!columnsList.includes("ItemMakeName") ? 0 : ""}
        />
      }
     
      {
        <GridColumn
          field="MobileColor"
          title="Mobile Color"
          width={!columnsList.includes("MobileColor") ? 0 : ""}
        />
      }
      {<GridColumn field="ModelName" title="Model Name" />}
      {
        <GridColumn
          field="ItemCondition"
          title="Item Condition"
          width={!columnsList.includes("ItemCondition") ? 0 : ""}
        />
      }
      {
        <GridColumn
          field="InternalMemory"
          title="Internal Memory"
          width={!columnsList.includes("InternalMemory") ? 0 : ""}
        />
      }
      
      {
        <GridColumn
          field="VATRATENAME"
          title="VAT Rate Name"
          width={!columnsList.includes("VATRateName") ? 0 : ""}
        />
      }

      {
        <GridColumn
          field="ItemCount"
          title="Item Count"
          width={!columnsList.includes("ItemCount") ? 0 : ""}
        />
      }

    </Grid>
  );

  const defaultShopItem = {
    "id": 0,
		"description": "[ALL SHOPS]"
  };


  return (
    <div>
      {/* <FilterMobile /> */}

      <div className="card-container">
        <React.Fragment>
          <div className="d-flex justify-space-between mblFlexDirectionColumn reportsTopCard">
            <div className="viewMobilesField">
              <Label>Report&nbsp;</Label>
              <ComboBox
                className="fieldWidth"
                data={reportsList}
                textField="reportName"
                filterable={true}
                ref={reportsComboRef}
                onFilterChange={reportsFilterChange}
              />
            </div>
            <div className="viewMobilesField">
              <Label>Shops&nbsp;</Label>
              <ComboBox
                className="fieldWidth"
                data={branchesList}
                textField="description"
                filterable={true}
                onFilterChange={shopsFilterChange}
                ref={shopsComboRef}
                defaultValue={defaultShopItem}
              />
            </div>
            <div className="viewMobilesField">
              {/* <button type="button" className="Btn mt-5" onClick={filterData}> */}
              <button
                type="button"
                className="changed"
                onClick={loadReportDataEvent}
              >
                Load Report
              </button>
            </div>
          </div>
          {grid}
        </React.Fragment>
      </div>
    </div>
  );
};

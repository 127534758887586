export const LOGGED_IN = "LOGGED_IN";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const GET_MOBILES = "GET_MOBILES";
export const GET_NETWORKS = "GET_NETWORKS";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_COLORS = "GET_COLORS";
export const GET_INTERNAL_MEMORY = "GET_INTERNAL_MEMORY";
export const GET_GRADES = "GET_GRADES";
export const GET_ITEM_MODEL = "GET_ITEM_MODEL";
export const GET_ITEM_MAKE = "GET_ITEM_MAKE";
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_LIST = "GET_REPORTS_LIST";
export const LOAD_REPORT = "LOAD_REPORT";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOGGING_IN_ERROR = "LOGGING_IN_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MOBILES = "CLEAR_MOBILES";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
export const LOGGED_IN_AGAIN = "LOGGED_IN_AGAIN";

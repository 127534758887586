import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { clearError, loggedIn } from "../../actions/login.action";
import loginIcon from "../../assets/login.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const { isSuccess, loggedInUser, isLoginError } = useSelector(
    (state) => state.loginOn
  );
  const usernameRef = useRef();
  const passwordRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("username", loggedInUser.username);
      localStorage.setItem("_t", loggedInUser.token);
      dispatch(clearError());
      setisLoading(false);
      navigate("/app");
    }
    if (isLoginError) {
      setisLoading(false);
      setError(true);
    }
  }, [isSuccess, loggedInUser, isLoginError]);
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState(false);
  const submit = () => {
    if (isLoginError === true) {
      dispatch(clearError());
    }
    let user = usernameRef.current.value.split("@");
    let username = user[0];
    let domain = user[1];
    let payload = JSON.stringify({
      username: username,
      password: passwordRef.current.value,
      domain: domain,
    });
    dispatch(loggedIn(payload));
    setisLoading(true);
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        submit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  return (
    <div className="">
      <div className="bgImg">
        <div className="centerCard card">
          <div className="login">
            <img
              src={loginIcon}
              alt="login Icon"
              className={"k-icon"}
              style={{ width: "auto", height: 70, marginTop: 5 }}
            />
            <div>
              <h3 className="font-weight-400">Login To Your Account</h3>
              <input
                className="loginForm"
                type="text"
                id="header-search"
                placeholder="Username@domain"
                name="username"
                ref={usernameRef}
              />
            </div>
            <div>
              <input
                className="loginForm"
                type="password"
                id="header-searchp"
                placeholder="Password"
                name="password"
                ref={passwordRef}
              />
            </div>
            {isLoading && <p>Logging in...Please Wait</p>}
            {error && (
              <p style={{ color: "red" }}>Wrong Username or Password</p>
            )}
            <button type="button" className="loginBtn" onClick={submit}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import {
  GET_ITEM_MAKE,
  START_LOADING,
  STOP_LOADING,
  SUCCESS,
  ERROR,
} from "../types/index";

import * as api from "../api/index.js";

export const getItemMakeList = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.getItemMakeList();
    dispatch({ type: GET_ITEM_MAKE, payload: data });
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

import { combineReducers } from "redux";
import LoginReducer from "./login.reducer";
import GetMobilesReducer from "./mobiles.reducer";
import GetListsReducer from "./lists.reducer";
import GetMakeItemReducer from "./itemMake.reducer";
import GetItemModelReducer from "./itemModel.reducer";
import GetReportsReducer from './customReports.reducer'
const rootReducer = combineReducers({
  loginOn: LoginReducer,
  mobilesList: GetMobilesReducer,
  allLists: GetListsReducer,
  itemMake: GetMakeItemReducer,
  itemModel: GetItemModelReducer,
  loadReportReducer: GetReportsReducer
});

export default rootReducer;

import {
  CLEAR_MOBILES,
  GET_MOBILES,
  START_LOADING,
  STOP_LOADING,
} from "../types/index.js";

const GetMobilesReducer = (
  state = {
    IsError: false,
    isSuccess: false,
    isLoading: undefined,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_MOBILES:
      return {
        ...state,
        mobilesList: action.payload,
        isSuccess: true,
      };
    case CLEAR_MOBILES:
      return {
        ...state,
        mobilesList: [],
        isSuccess: true,
      };
    default:
      return state;
  }
};
export default GetMobilesReducer;
